<!--
 * @Author: leo
 * @Description: 个人信息表 - 服务端可用编辑
-->
<template>
    <div v-loading.fullscreen.lock="isLoading" class="details-list">
        <!-- 主申请人 -->
        <User v-if="dataList[0]" :info="dataList[0]" class="anchor anchor0" />
        <!-- 家庭成员信息 -->
        <Family
            v-if="dataList[1]"
            :info="dataList[1]"
            class="anchor anchor1"
        />
        <!-- 基本资料 -->
        <Basics v-if="dataList[2]" :info="dataList[2]" class="anchor anchor2" />
    </div>
</template>

<script>
import User from './User/User.vue'
import Family from './Family/Family.vue'
import Basics from './Basics/Basics.vue'


import { mapState } from 'vuex'
export default {
    components: {
        User, Family, Basics
    },
    data() {
        return {
            isLoading: false,
        }
    },
    created() {
        this.getData()
    },
    computed: {
        ...mapState('gifted', ['dataList'])
    },
    methods: {
        async getData() {
            this.isLoading = true
            await this.$utils.saveGiftedStatus(this.$route.params.id)
            this.isLoading = false
        }
    },
    mounted() {
        // 双击复制
        document.addEventListener('dblclick', (e) => {
            const { outerText, localName } = e.path[0]
            if (localName === 'span') {
                const input = document.createElement("input")
                document.body.appendChild(input)
                input.value = outerText
                input.select()
                try {
                    if (document.execCommand("copy", false)) {
                        this.$message.success("复制成功!")
                    } else {
                        this.$message.success("复制失败!")
                    }
                } catch (error) {
                    console.log(error, "error")
                } finally {
                    input.remove()
                }
            }
        })
    }
}
</script>

<style lang="less" scoped>
.details-list {
    // line-height: 34px;
    >div + div {
        margin-top: 20px;
    }
    /deep/ .details-module {
        .el-row {
            padding: 0 10px;
            .el-col {
                padding: 5px 0;
                display: flex;
                align-items: center;
                line-height: 30px;
                >span:first-of-type {
                    min-width: 115px;
                    margin-right: 10px;
                    // line-height: 22px;
                }
                >span:nth-of-type(2) {
                    flex: 1;
                    transition: background .1s ease-in;
                    border-radius: 6px;
                    padding-left: 8px;
                    word-break:break-all;
                    &:hover {
                        background-color: #e7f6ff;
                    }
                }
            }
        }
    }
    /deep/ .common-part {
        padding: 12px;
    }
    // 表单label统一居左
    /deep/ .el-form-item__label {
        text-align: left;
        // padding-left: 10px;
    }
}
</style>
