<template>
    <div class="details-box warp">
        <!-- 主体信息 -->
        <Info ref="main"/>
        <!-- 侧边栏 -->
        <div class="sidebar">
            <p class="title">完善客户信息导航</p>
            <div class="divide-line"></div>
            <ul class="ul-list" @click="scrollInto">
                <li class="active">主申请人</li>
                <li>家庭成员信息</li>
                <li>基本资料</li>
                <!-- <li>学历/专业资格</li>
                <li>工作经验</li>
                <li>居住信息</li>
                <li>语言能力</li>
                <li>专业技能及行业</li>
                <li>辅助资料</li>
                <li>人才清单</li>
                <li>资产</li>
                <li>负债</li> -->
            </ul>
        </div>
    </div>
</template>

<script>
import Info from '../../../components/DetailsEdit/Transfer/Info.vue'
export default {
    name: 'GiftedEdit',
    components: {
        Info
    },
    data() {
        return {
            timeOff: true, // flag值
            eleTopArr: [],
            timer: null,
        }
    },
    created() {
        window.addEventListener('scroll', this.listenScroll)
    },
    mounted() {
        // let anchors = document.querySelector('.details-list').children
        // for(let i = 0; i < anchors.length; i++) {
        //     anchors[i].classList.add(`anchor${i}`)
        // }
        // console.time()
        // this.$nextTick(() => {
        //     console.log(document.querySelectorAll(".card-heard"))
        //     setTimeout(() => {
        //         console.log(document.querySelectorAll(".card-heard"))
        //     },3000)
        //     console.log(JSON.stringify(this.$refs.main.$el.innerHTML))
        //     console.timeEnd()
        // })
    },
    methods: {
        // 点击锚点
        scrollInto(e) {
            if(e.target.nodeType !== 1 || (e.target.nodeName.toLowerCase() !== "li")) return
            this.timeOff = false
            let lis = e.target.parentNode.querySelectorAll('li')
            lis.forEach(item => {
                item.classList.remove("active")
            })
            e.target.classList.add("active")
            // 获取点击的锚点
            let charClass = ''
            for(let i = 0; i < lis.length; i++) {
                if(lis[i].classList.contains("active")) {
                    charClass = 'anchor' + i
                    break
                }
            }
            // console.log(charClass)
            let scrollTarget = document.querySelector(`.${charClass}`)
            scrollTarget.scrollIntoView({
                block: "start",
                behavior: "smooth",
            })
            setTimeout(() => {
                this.timeOff = true
            }, 1000)
        },
        listenScroll() {
            if(this.timeOff) {
                this.timeOff = false
                setTimeout(() => {
                    this.boxScrollT()
                    let scrollTop = (window.scrollY || document.documentElement.scrollTop || document.body.scrollTop) + 300
                    // console.log(scrollTop)
                    let lis = document.getElementsByClassName('ul-list')[0].children
                    // console.log(lis)
                    for(let i = 0; i < lis.length; i++) {
                        if(i === lis.length - 1) {
                            if(this.eleTopArr[i] < scrollTop) {
                                lis.forEach(item => {
                                    item.classList.remove("active")
                                })
                                lis[i].classList.add("active")
                            }
                        } else {
                            if(this.eleTopArr[i] < scrollTop && scrollTop < this.eleTopArr[i + 1]) {
                                lis.forEach(item => {
                                    item.classList.remove("active")
                                })
                                lis[i].classList.add("active")
                            }
                        }
                    }
                    this.timeOff = true
                }, 500)
            }
        },
        boxScrollT() {
            if(this.eleTopArr.length > 0) return
            // let modules = document.getElementsByClassName('details-list')[0].children
            let modules = document.getElementsByClassName('details-list')[0].querySelectorAll('.anchor')
            modules.forEach(item => {
                this.eleTopArr.push(item.offsetTop)
            })
        },
    },
}
</script>

<style lang="less" scoped>
.warp {
    display: flex;
    height: 100%;
    // overflow: hidden;
}

.sidebar {
    border: 1px solid #e4e4e4;
    background-color: #fff;
    padding: 15px;
    position: fixed;
    left: 970px;
    top: 0;
    // transform: translateY(-50%);
    .title {
        font-size: 18px;
        font-weight: bold;
    }
    .divide-line {
        margin: 7px 0;
        width: 100px;
        height: 2px;
        background-color: #e4e4e4;
    }
    .ul-list {
        font-size: 16px;
        >li {
            cursor: pointer;
            margin-left: 20px;
            line-height: 1.8;
            position: relative;
        }
        >li.active {
            font-weight: bold;
            color: #409eff;
        }
        >li.active::before {
            content: '';
            position: absolute;
            width: 30px;
            height: 6px;
            top: 7px;
            left: -40px;
            background-color: #409eff;
        }
    }
}
</style>
