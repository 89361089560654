<!--
 * @Author: zhai
 * @Description: 基本资料
-->
<template>
    <div class="details-module">
        <Container title="基本资料" padding="0">
            <Applicant :data="info" />
            <Spouse v-if="dataList[0].married === 1" :data="info" />
            <Child :data="info" />
            <!-- <Background :data="info" /> -->
        </Container>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Applicant from './C/Applicant/Applicant.vue';
import Spouse from './C/Spouse/Spouse.vue';
import Child from './C/Child/Child.vue';
import Background from './C/Background/Background.vue'
export default {
    props: {
        info: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    computed: {
        ...mapState('gifted', ["dataList"])
    },
    components: {
        Applicant,
        Spouse,
        Child,
        Background
    },
    data() {
        return {

        }
    },
    mounted() {
        console.log(this.Basics)
    },
}
</script>

<style lang="less" scoped>

</style>
