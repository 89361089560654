<!--
 * @Author: Jacob.Zhang
 * @Description: 个人信息表 - 主申请人信息
-->
<template>
    <div v-loading="isLoading">
        <Container title="主申请人" padding="0">
            <!-- <div class="description-box"> -->
                <!-- <p>{{ currentItem.component_score.age.answer }}，得分<span class="text-red">{{ currentItem.component_score.age.score }}</span>分</p> -->
                <!-- <p>{{ dataList[0].component_score.age.answer }}，得分<span class="text-red">{{ dataList[0].component_score.age.score }}</span>分</p>
            </div> -->
            <div class="title-box">
                <span>主申信息</span>
                <div class="title-btn">
                    <el-button v-if="!isEdit" type="primary" @click="isEdit = true">编辑</el-button>
                    <el-button v-if="isEdit" @click="isEdit = false">取消</el-button>
                    <el-button v-if="isEdit" type="primary" :loading="isLoading" @click="saveInfo">保存</el-button>
                </div>
            </div>
            <!-- 回显信息 -->
            <UserDetail v-if="!isEdit" :info="currentItem" />
            <!-- 编辑 -->
            <User v-if="isEdit" :ref="info.component" :info.sync="currentItem" :loading.sync="isLoading" @update="isEdit = false" />
        </Container>
    </div>
</template>

<script>
import User from './C/EditUser.vue'
import UserDetail from './C/UserDetail.vue'
import { mapState } from 'vuex'
export default {
    props: {
        info: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    components: {
        User, UserDetail,
    },
    created() {
        this.currentItem = JSON.parse(JSON.stringify(this.info))
    },
    computed: {
        ...mapState('gifted', ['dataList'])
    },
    data() {
        return {
            isLoading: false,
            isEdit: false,
            currentItem: {}, // 主申人信息（编辑&回显共用）
        }
    },
    methods: {
        saveInfo() {
            this.$refs[this.info.component].checkInfo()
        },
    }
}
</script>

<style lang="less" scoped>

</style>
