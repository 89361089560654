<!--
 * @Author: zhai
 * @Description: 主申人详情
-->
<template>
    <div class="pdt-20 pdb-20">
        <p>1、至少一名直系家庭成员（已婚配偶、父母、兄弟姊妹、子女）是现居于香港的香港永久性居民；</p>
        <div>
            <span v-if="data.background.background_member.has === 'Y'">满足 - {{info}}</span>
            <span v-else-if="data.background.background_member.has === 'N'">不满足</span>
            <span v-else>-</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default:() => {}
        },
    },
    data() {
        return {
            info: ''
        }
    },
    mounted() {
        if (this.data.background.background_member.has === 'Y') {
            let arr = this.data.background.subset.filter((v) => v.id == this.data.background.background_member.info)
            if (arr.length > 0) {
                this.info = arr[0].subset_name
            } else {
                this.info = '';
            }
             
        } else {
            this.info = '';
        }
    },
}
</script>

<style lang="less" scoped>

</style>