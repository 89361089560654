<!--
 * @Author: filename
 * @Description: 家庭成员信息
-->

<template>
    <div v-if="info">
        <!-- 配偶 -->
        <Spouse v-if="dataList[0].married != 2 && dataList[0].married != 3" :data="data" />
        <!-- 子女 -->
        <Children :data="data" />
        <!-- 父母 -->
        <Parent :data="data" />
        <!-- 兄弟姐妹 -->
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Spouse from './C/Spouse/Spouse.vue'
import Children from './C/Children/Children.vue'
import Parent from './C/Parent/Parent.vue'
export default {
    props: {
        info: {
            type: Object,
            default: () => {}
        }
    },
    components: {
        Spouse,
        Children,
        Parent,
    },
    computed: {
        ...mapState('gifted', ['dataList'])
    },
    data() {
        return {
            data: {}
        }
    },
    created() {
        this.data = JSON.parse(JSON.stringify(this.info))
        console.log("--------------", this.dataList)
    },
}
</script>

<style lang="less" scoped>

</style>
